.layout-menu-container {
	border: 1px solid #ddd;
	border-radius: .5em;
	height: 97vh;
	margin-left: .6em;
	overflow: hidden;
	background-color: white;
}
.svg-main-container {
	border: 1px solid #ddd;
  padding: 30px 20px 5px 20px;
	min-height: 87vh;
	border-radius: .5em;
  background-color: white;
  margin-bottom: 1.5vh;
}

.layout-menu {
	list-style-type: none;
	margin: 0;
	padding: 0;
	li {		
		a {
			cursor: pointer;
			text-decoration: none;
			display: flex;
			max-height: $sidebarHeight; 
			flex-direction: column;
			justify-content: center;
			align-items: center;
			align-content: center;
			color: var(--text-color);
			transition: color $transitionDuration;
			border-radius: 1px;
			padding: .75rem 1rem;
			transition: border .15s;
			border-radius: 0px 3px 3px 0px;
			border-right: 10px solid white;	
			.menuitem-toggle-icon {
				margin-left: auto;
			}
			h6 {
				width: 100px;
				font-size: .8em;
				text-align: center;
			}
			i {
				font-size: 2em;
				padding: 5px 0px;
			}	
			&:hover {
				background-color: var(--surface-hover);
			}
			
		}
		
		&.active-menuitem {
			> a {
				border-right: 10px solid $mainColor;
			}
		}

		&.disabled-menuitem {
			> a {
				color:var(--text-disabled);
			}
		}
		
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			flex-direction: column;
			justify-content: space-around;
		}
	}
}

.svg-main-layout-sidebar {
	transition: transform .4s;
	position: fixed;
	max-width: 150px;
	transform: translateX(-($sidebarWidth + 9vw));
	z-index: 999;
}

.active-sidebar {
	transform: translateX(0);
	transition: transform .5s;
}

.active-sidebar-right.layout-menu-container {
	min-width: $sidebarWidth + 0vw;
}
.active-sidebar-right.svg-main-layout-right {
	width: 98%;
	transition: width .5s;
	margin-left: 1%;
}
