.p-inputtext {
  width: 100%;
}
.svg-inputs-error {
  color: #E74C3C;
  width: 100%;
  padding: 2px;
}
.svg-inputs-error::after {
  content: ' es requerido';
}
.svg-sign-form {
  min-width: 200px;
  min-height: 350px;
}
