.svg-main-layout-right {
	margin-top: $sidebarMarginTop;
	margin-right: ($sidebarMarginLeft + .5vw);
	margin-left: 11rem;
	transition: width .5s;

	.svg-main-background {
		background-color: $mainColor;
		position: absolute;
		width: 100%;
		left: 0;
		top: 0px;
		z-index: -10;
		height: 9.9rem;
	}
	> .svg-main-header {
		color: white;
		text-align: center;
		position: relative;
		padding: 10px;
		width: 100%;
		> h3 {
			font-weight: bold;
		}
		.svg-sidebar-burger {
			// position: absolute;
			// left: 0;
			color: white;
		}
		.svg-sidebar-settings {
			// position: absolute;
			// right: 0;
			color: white;
		}
		.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
			color: white !important;
		}
		.svg-sidebar-alert {
			position: absolute;
			right: 60px;
		}
		// border-bottom: 1px solid $mainColor;
		// margin-bottom: 3vh;
	}
	> .svg-main-content {
		width: 100%;
		border-radius: 20px;
		// height: $mainContentHeigh - 5vh;
	}
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
	background-color: $mainColor !important;
}
.p-tabview-nav-container {
	span {
		margin: 0 .5rem;
	}
}
