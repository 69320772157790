/* General */
$fontSize:10px;
$borderRadius:12px;
$transitionDuration:.2s;

// $mainColor: #33CC33;
$mainColor: #4CAF50;
$sidebarWidth: 10.5vw;
$sidebarHeight: 11vh;
$sidebarMarginTop: 1.5vh;
$sidebarMarginLeft: .4vw;
$mainContentHeigh: 90vh;

