/* Fonts - https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping */

/* font-weight: 200; Extra Light */
@font-face {
  font-family: 'Segoe-custom';
  src: local('Segoe UI Light'),
    url('./assets/fonts/segoe-ui-light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Segoe-custom';
  src: local('Segoe UI Light Italic'),
    url('./assets/fonts/segoe-ui-light-italic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
/* ---------------------------- */

/* font-weight: 300; Light */
@font-face {
  font-family: 'Segoe-custom';
  src: local('Segoe UI Semilight'),
    url('./assets/fonts/segoe-ui-semilight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Segoe-custom';
  src: local('Segoe UI Semilight Italic'),
    url('./assets/fonts/segoe-ui-semilight-italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
/* ---------------------------- */

/* font-weight: 400;  NORMAL (Regular) */
@font-face {
  font-family: 'Segoe-custom';
  src: local('Segoe UI Regular'),
    url('./assets/fonts/segoe-ui-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
/* ---------------------------- */

/* font-weight: 600; Semi-bold (Demi Bold) */
@font-face {
  font-family: 'Segoe-custom';
  src: local('Segoe UI Semibold'),
    url('./assets/fonts/segoe-ui-semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Segoe-custom';
  src: local('Segoe UI Semibold Italic'),
    url('./assets/fonts/segoe-ui-semibold-italic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
/* ---------------------------- */

/* font-weight: 700; BOLD */
@font-face {
  font-family: 'Segoe-custom';
  src: local('Segoe UI Bold'),
    url('./assets/fonts/segoe-ui-bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Segoe-custom';
  src: local('Segoe UI Bold Italic'),
    url('./assets/fonts/segoe-ui-bold-italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
/* ---------------------------- */

/* font-weight: 900; BLACK (Heavy) */
@font-face {
  font-family: 'Segoe-custom';
  src: local('Segoe UI Black'),
    url('./assets/fonts/segoe-ui-black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Segoe-custom';
  src: local('Segoe UI Black'),
    url('./assets/fonts/segoe-ui-black-italic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
/* ---------------------------- */
