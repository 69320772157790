.svg-filter-odoo-input {
  flex-grow: 1;
  border-color: transparent;

  &:focus {
    border-color: transparent;
    outline: none;
  }
}

.svg-filter-odoo-border {
  border-width: 2px;
  border-color: gray;
  border-radius: 2;
}

.svg-filter-odoo-menu {
  width: auto !important;
}

.svg-filter-odoo-category {

  margin-left: 4px;
}

.svg-filter-odoo-display {
  padding-left: 1;
  padding-right: 1;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}